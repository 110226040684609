<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import jaLocale from "@fullcalendar/core/locales/ja"
import axios from 'axios'
const moment = require("moment");
import DialogCard from '@/components/DialogCard'
import LoadingMessage from '@/components/LoadingMessage'


export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    DialogCard,
    LoadingMessage
  },
  props: {
    user: Object
  },
  data() {
    return {
      snackbarFlag: false,
      snackbarTimeout: 3000,
      snackbarText: '',
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin ],
        locale: jaLocale,
        firstDay: 1,
        initialView: 'dayGridMonth',
        contentHeight: 'auto',
        weekends: false,
        height: 'auto',
        googleCalendarApiKey: process.env.VUE_APP_GOOGLE_API_KEY,
        eventSources: [
          {
            googleCalendarId: 'ja.japanese#holiday@group.v.calendar.google.com',
            className: 'event_holiday',
            success: function (events) {
              events.forEach(event => {
                event.title=`🎉${event.title}`
                event.color="red"
                event.description=process.env.VUE_APP_HOLIDAY_WORD
                event.url = ""
              });
            }
          }
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        eventClick: this.eventClick
      },
      dialog: false,
      loadingInfo: {
        loading: true,
        message: '読み込み中...'
      },
      calendarData: {
        title: '',
        st_tm: '',
        ed_tm: '',
        location: '',
        description: ''
      }
    }
  },
  created () {
    this.getSatelliteEvent()
  },
  methods: {
    getSatelliteEvent: function () {
      const me = this;
      axios.post(process.env.VUE_APP_SCHEDULE_CALENDAR_API)
        .then(response => {
          const scheduleList = response.data.message
          const events = {
            events: scheduleList
          }
          me.calendarOptions.eventSources.push(events)
          me.loadingInfo.loading = false
        }).catch(error => {
          console.log(error);
        });
    },
    eventClick: function (info) {
      const stDate = moment(info.event.start)
      const edDate = moment(info.event.end)
      if (info.event.extendedProps.description === process.env.VUE_APP_TEIKYU_WORD || info.event.extendedProps.description === process.env.VUE_APP_HOLIDAY_WORD) {
        this.showSnackbar(info.event.title)
      } else {
        this.calendarData.description = info.event.extendedProps.description
        this.calendarData.location = info.event.extendedProps.location
        this.calendarData.st_tm = stDate
        this.calendarData.ed_tm = edDate
        this.calendarData.title = info.event.extendedProps.summary
        this.dispDialog()
      }
    },
    dispDialog() {
      this.dialog = true
    },
    onClose() {
      this.dialog = false
    },
    onSubmit(params) {
      this.dialog = false
      this.user.st_tm = this.calendarData.st_tm
      this.user.ed_tm = this.calendarData.ed_tm
      this.user.regist_type = this.calendarData.location
      this.$router.push('/form')
    },
    showSnackbar(text) {
      this.snackbarFlag = true
      this.snackbarText = text
    }
  }
}
</script>
<style>
.fc-daygrid-event {
  white-space: normal!important;
  word-wrap:break-word!important;
}
</style>
<template>
  <v-container class="text-center">
    <h3>予約したい日時を選択してください</h3>
    <v-row>
      <v-col>
       <FullCalendar :options="calendarOptions"/>
      </v-col>
    </v-row>
    <!-- dialog -->
    <div>
      <v-dialog v-model="dialog" width="600px" scrollable>
        <dialog-card
          v-on:clickSubmit="onSubmit"
          v-on:clickClose="onClose"
          :calendarData="calendarData"
        ></dialog-card>
      </v-dialog>
    </div>
    <!-- loading -->
    <div>
      <loading-message
        :loadingInfo="loadingInfo"
      ></loading-message>
    </div>
    <!-- Snackbar -->
    <div>
      <v-snackbar
        v-model="snackbarFlag"
        color="error"
        :timeout="snackbarTimeout"
        :multi-line="true"
        top
      >
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackbarFlag = false"
          >
            <v-icon
            right
            >
            mdi-close-circle-outline
          </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>