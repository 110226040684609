<template>
  <v-card width="650px" class="mx-auto mt-5">
    <v-container>
      <v-card-title>
        <h1 class="display-1">予約確認</h1>
      </v-card-title>
      <v-card-subtitle>下記内容で登録します。修正する場合は「修正する」ボタンを押してください。</v-card-subtitle>

      <v-card-text>日時: {{st_tm}}〜{{ed_tm}}</v-card-text>

      <v-row>
        <v-col>
          <v-card-text>
            <v-text-field v-model="user.name" label="お名前" outlined readonly/>
            <v-text-field v-model="user.phone" label="電話番号（携帯番号）" outlined readonly/>
            <v-text-field v-model="user.email" label="メールアドレス" outlined readonly/>
            <v-text-field v-model="user.zip_code" label="郵便番号" outlined readonly/>
            <v-text-field v-model="user.address1" label="都道府県・市区町村・番地" outlined readonly/>
            <v-text-field v-model="user.address2" label="建物名・号室" outlined readonly/>
            <v-text-field v-model="this.genders[user.gender - 1]" label="性別" outlined readonly />
            <v-text-field v-model="user.member_cnt" label="予約人数" outlined readonly/>
            <v-textarea v-model="user.note" label="メモ" outlined readonly/>
          </v-card-text>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn color="error" large block class="font-weight-bold" v-on:click="$router.back()" :disabled="loading">修正する</v-btn>
        </v-col>
        <v-col>
          <v-btn color="success" large block class="font-weight-bold" v-on:click="submit" :loading="loading">登録する</v-btn>
        </v-col>
      </v-row>
      <!-- Snackbar -->
      <div>
        <v-snackbar
          v-model="snackbarFlag"
          color="error"
          :timeout="snackbarTimeout"
          :multi-line="true"
          top
        >
          {{ snackbarText }}
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackbarFlag = false"
            >
              <v-icon
              right
              >
              mdi-close-circle-outline
            </v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    user: Object
  },
  data() {
    return {
      snackbarFlag: false,
      snackbarTimeout: 3000,
      snackbarText: '',
      loading: false,
      genders: ["男性", "女性"],
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.loading) {
      next(false)
    } else {
      next()
    }
  },
  computed: {
    st_tm: {
      get: function() {
        return this.user.st_tm.format('YYYY年MM月DD日 HH:mm')
      }
    },
    ed_tm: {
      get: function() {
        return this.user.ed_tm.format('HH:mm')
      }
    }
  },
  methods: {
    confirmSave(event) {
      const answer = window.confirm("編集中のものは保存されませんが、よろしいですか？")
      if (answer) {
        next()
      } else {
        next(false)
      }
    },
    submit() {
      this.loading = true
      const me = this;
      
      const params = new URLSearchParams()
      params.append('user_data', JSON.stringify(this.user))
      axios.post(process.env.VUE_APP_REGIST_SCHEDULE_CALENDAR_API, params)
        .then(response => {
          me.loading = false

          if (response.status == 200) {
            me.$router.replace('/done')
          } else {
            me.loading = false
            me.showSnackbar("登録に失敗しました。")
          }

        }).catch(error => {
          console.log(error);
          me.loading = false
          me.showSnackbar("登録に失敗しました。")
      });
    },
    showSnackbar(text) {
      this.snackbarFlag = true
      this.snackbarText = text
    }
  }
}
</script>