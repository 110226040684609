import Vue from 'vue'
import VueRouter from 'vue-router'
import Rules from '../views/Rules.vue'
import Index from '../views/Index.vue'
import User from '../views/User.vue'
import Form from '../views/Form.vue'
import Confirm from '../views/Confirm.vue'
import Calendar from '../views/Calendar.vue'
import Done from '../views/Done.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/rules',
    name: 'Rules',
    component: Rules
  },
  {
    path: '/',
    name: 'Index',
    component: Index,
    children: [
      {
        path: '/calendar',
        component: Calendar
      },
      {
        path: '/form',
        component: Form
      },
      {
        path: '/confirm',
        component: Confirm
      },
      {
        path: '/done',
        component: Done
      },
      {
        path: '/user',
        component: User
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
