<template>
  <v-card width="650px" class="mx-auto mt-5">
    <v-container>
      <v-card-title>
        <h1 class="display-1">予約フォーム</h1>
      </v-card-title>
      
      <v-card-text>日時: {{st_tm}}〜{{ed_tm}}</v-card-text>

      <v-form ref="send_data">
        <v-row>
          <v-col>
              <v-text-field v-model="user.name" label="お名前" outlined :rules="[required]"/>
              <v-text-field v-model="user.phone" label="電話番号（携帯番号）" outlined :rules="[required, phoneRules.regex]"/>
              <v-text-field v-model="user.email" label="メールアドレス" outlined :rules="[required, emailRules.regex]"/>
          </v-col>
        </v-row>
        <v-row justify="center" align="baseline">
          <v-col>
            <v-text-field v-model="user.zip_code" label="郵便番号（ハイフン無し）" outlined :rules="[required, zipRules.regex]"/>
          </v-col>
          <v-col>
            <v-btn class="font-weight-bold" v-on:click="searchAddressInfo">住所検索</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <v-text-field v-model="user.address1" label="都道府県・市区町村・番地" outlined :rules="[required]"/>
              <v-text-field v-model="user.address2" label="建物名・号室" outlined />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <v-select
                v-model="user.gender"
                item-text="label"
                item-value="value"
                :items="genders"
                label="性別"
                outlined
                :rules="[requiredselect]"
              ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
              <v-text-field v-model="user.member_cnt" label="予約人数" outlined :rules="[required, phoneRules.regex]"/>
              <v-textarea v-model="user.note" label="メモ" outlined />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn large block class="font-weight-bold" v-on:click="$router.back()">戻る</v-btn>
          </v-col>
          <v-col>
            <v-btn color="info" large block class="font-weight-bold" v-on:click="submit">確認</v-btn>
          </v-col>
        </v-row>
      </v-form>

    </v-container>
  </v-card>

</template>

<script>
const axios = require('axios')
const axiosJsonpAdapter = require("axios-jsonp")
const url = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode='

export default {
  props: {
    user: Object
  },
  data() {
    return {
      success: false,
      required: value => !!value || "必ず入力してください",
      requiredselect: value =>  value.length > 0 || "選択してください",
      phoneRules: {
        regex: v => /^[0-9]*$/.test(v) || '数字のみ入力してください'
      },
      zipRules: {
        regex: v => /^[0-9]{7}$/.test(v) || '7桁の数字のみ入力してください'
      },
      emailRules: {
        regex: v => /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式が正しくありません'
      },
      genders: [{ label: "男性", value: "1"}, { label: "女性", value: "2"}],
    }
  },
  computed: {
    st_tm: {
      get: function() {
        return this.user.st_tm.format('YYYY年MM月DD日 HH:mm')
      }
    },
    ed_tm: {
      get: function() {
        return this.user.ed_tm.format('HH:mm')
      }
    }
  },
  methods: {
    submit() {
      if (this.$refs.send_data.validate()) {
        this.success = true
        this.$router.push('/confirm')
      } else {
        this.success = false
      }
    },
    searchAddressInfo() {
      axios.get(url + this.user.zip_code, {
        adapter: axiosJsonpAdapter
      }).then((res) => {
        if (res.data.results === null) {
          this.user.address1 = '郵便番号が不正です'
        } else {
          const addressData = res.data.results[0]
          this.user.address1 = addressData['address1'] + addressData['address2']　+ addressData['address3'];

        }
      })
    }
  }
}
</script>