<template>
  <v-card width="650px" class="mx-auto mt-5">
    <v-container>
      <v-card-title>
        <h1 class="display-1">ご予約完了</h1>
      </v-card-title>
      <v-card-text>{{user.name}}様のご予約が完了しました</v-card-text>

      <v-row>
        <v-col v-if="this.user.isClient">
          <v-btn color="success" block class="font-weight-bold" v-on:click="closeWindows">閉じる</v-btn>
        </v-col>
        <v-col v-else>
          <v-btn color="success" block class="font-weight-bold" v-on:click="openTop">トップへ戻る</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
const moment = require("moment");

export default {
  props: {
    user: Object
  },
  data() {
    return {
      doneFlg: true
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.doneFlg) {
      next(false)
    } else {
      next()
    }
  },
  created () {
    this.initializeLiff()
  },
  methods: {
    initializeLiff() {
      var me = this
      liff.init(
        {
          liffId: process.env.VUE_APP_LIFF_ID
        },
        data => {
          console.log('LIFF initialized!')
          me.lineSendMessage()
        },
        err => {
          console.log('LIFF initialization failed', err)
        }
      )

    },
    openTop() {
      this.doneFlg = false
      const url = "https://www.i-enter.co.jp/"
      location.replace(url)
    },
    closeWindows() {
      liff.closeWindow()
    },
    lineSendMessage() {
      liff.sendMessages([
        {
          "type": "text",
          "text": "ご予約が完了しました"
        },
        {
          "type": "flex",
          "altText": "ご予約完了",
          "contents": {
            "type": "bubble",
            "size": "giga",
            "body": {
              "type": "box",
              "layout": "vertical",
              "spacing": "md",
              "contents": [
                {
                  "type": "text",
                  "text": "ご予約完了",
                  "size": "xl",
                  "weight": "bold",
                  "align": "center",
                  "color": "#1DB446"
                },
                {
                  "type": "box",
                  "layout": "vertical",
                  "spacing": "sm",
                  "contents": [
                    {
                      "type": "box",
                      "layout": "baseline",
                      "contents": [
                        {
                          "type": "text",
                          "text": "お名前:",
                          "size": "sm",
                          "flex": 0
                        },
                        {
                          "type": "text",
                          "text": `${this.user.name} 様`,
                          "align": "end",
                          "offsetTop": "none",
                          "size": "sm",
                          "flex": 8
                        }
                      ]
                    },
                    {
                        "type": "box",
                        "layout": "baseline",
                        "contents": [
                          {
                            "type": "text",
                            "text": "性別:",
                            "flex": 0,
                            "size": "sm"
                          },
                          {
                            "type": "text",
                            "text": this.user.gender === "1"?"男性":"女性",
                            "align": "end",
                            "size": "sm"
                          }
                        ]
                    },
                    {
                      "type": "box",
                      "layout": "baseline",
                      "contents": [
                        {
                          "type": "text",
                          "text": "郵便番号:",
                          "flex": 0,
                          "size": "sm"
                        },
                        {
                          "type": "text",
                          "text": this.user.zip_code,
                          "align": "end",
                          "size": "sm"
                        }
                      ]
                    },
                    {
                      "type": "box",
                      "layout": "baseline",
                      "contents": [
                        {
                          "type": "text",
                          "text": "住所:",
                          "flex": 0,
                          "size": "sm"
                        },
                        {
                          "type": "text",
                          "text": `${this.user.address1}${this.user.address2}`,
                          "align": "end",
                          "size": "sm",
                          "wrap": true
                        }
                      ]
                    },
                    {
                      "type": "box",
                      "layout": "baseline",
                      "contents": [
                        {
                          "type": "text",
                          "text": "メール:",
                          "flex": 0,
                          "size": "sm",
                          "wrap": true
                        },
                        {
                          "type": "text",
                          "text": this.user.email,
                          "align": "end",
                          "size": "sm",
                          "wrap": true
                        }
                      ]
                    },
                    {
                      "type": "box",
                      "layout": "baseline",
                      "contents": [
                        {
                          "type": "text",
                          "text": "ご連絡先:",
                          "flex": 0,
                          "size": "sm"
                        },
                        {
                          "type": "text",
                          "text": this.user.phone,
                          "align": "end",
                          "size": "sm"
                        }
                      ]
                    },
                    {
                      "type": "box",
                      "layout": "baseline",
                      "contents": [
                        {
                          "type": "text",
                          "text": "ご予約人数:",
                          "flex": 0,
                          "size": "sm"
                        },
                        {
                          "type": "text",
                          "text": this.user.member_cnt,
                          "align": "end",
                          "size": "sm"
                        }
                      ]
                    },
                    {
                      "type": "box",
                      "layout": "baseline",
                      "contents": [
                        {
                          "type": "text",
                          "text": "メモ:",
                          "flex": 0,
                          "size": "sm"
                        },
                        {
                          "type": "text",
                          "text": this.user.note,
                          "align": "end",
                          "size": "sm"
                        }
                      ]
                    },
                    {
                      "type": "box",
                      "layout": "horizontal",
                      "contents": [
                        {
                          "type": "text",
                          "text": "ご予約日時:",
                          "size": "sm",
                          "flex": 0
                        },
                        {
                          "type": "text",
                          "text": moment(this.user.st_tm).format("YYYY年MM月DD HH:mm"),
                          "align": "end",
                          "size": "sm"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          }
        }
       ])
      .then(() => {
      })
      .catch((err) => {
      })

    }
  }

}
</script>

