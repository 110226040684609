<template>
  <div>
    <router-view v-bind:user.sync="user"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        uuid: 'PC',
        token: '',
        phone: '',
        email: '',
        name: '',
        zip_code: '',
        address1: '',
        address2: '',
        gender: {},
        st_tm: '',
        icon: '',
        member_cnt: '',
        note: '',
        isClient: false
      },
    }
  },
  created () {
    this.initializeLiff()
  },
  methods: {
    initializeLiff() {
      var me = this
      this.user.isClient = liff.isInClient()

      if (this.user.isClient) {
        liff.init(
          {
            liffId: process.env.VUE_APP_LIFF_ID
          },
          data => {
            if (liff.isLoggedIn()) {
              // IDトークン取得
              me.user.token = liff.getAccessToken()
            } else {
              this.loginAction()
            }
          },
          err => {
            console.log('LIFF initialization failed', err)
          }
        )

      }
    },
    loginAction() {
      // ログインまだ
      if (!liff.isLoggedIn()) {
        liff.login()
      }
    }
  }
}
</script>