<template>
  <v-main>
    <v-card>
      <v-container>
        <v-card-title>ユーザー情報</v-card-title>
        <v-card-text>トークン: {{localUser.token}}</v-card-text>
        <v-card-text>ユーザー名: {{localUser.name}}</v-card-text>
        <v-card-text>UUID: {{localUser.uuid}}</v-card-text>
        <v-card-text>メール: {{localUser.email}}</v-card-text>
        <v-img :src="localUser.icon" />
        <v-btn large block color="success" class="font-weight-bold" v-on:click="onClick" :loading="loading">情報取得</v-btn>
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    user: Object
  },
  computed: {
    localUser: {
      get: function() {
        return this.user
      }
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async onClick() {
      this.loading = true
      var me = this

      const params = new URLSearchParams()
      params.append('user_data', JSON.stringify(this.localUser))

      axios.post(process.env.VUE_APP_LINEVERIFY_API, params)
        .then(response => {
          me.loading = false
          console.log("resp="+JSON.stringify(response))
          const data = response.data
          me.localUser.uuid = data.userId
          me.localUser.name = data.displayName
          me.localUser.icon = data.pictureUrl
        }).catch(error => {
          me.loading = false
          console.log("errr="+ error);
      });
    }
  }
}
</script>