<template>
  <v-card>
    <v-card-title>{{calendarData.title}}</v-card-title>
    <v-card-text>日時: {{st_tm}}〜{{ed_tm}}</v-card-text>
    <v-card-text>場所: {{calendarData.location}}</v-card-text>
    <v-divider></v-divider>
    <v-card-text>
      <v-card-text style="white-space:pre-wrap; word-wrap:break-word;" outlined>{{calendarData.description}}</v-card-text>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text  @click="close">
        閉じる
      </v-btn>
      <v-btn color="success" @click="submit">
        予約する
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const moment = require("moment");

export default {
  props: {
    calendarData: Object
  },
  computed: {
    st_tm: {
      get: function() {
        return this.calendarData.st_tm.format('YYYY年MM月DD日 HH:mm')
      }
    },
    ed_tm: {
      get: function() {
        return this.calendarData.ed_tm.format('HH:mm')
      }
    }
  },
  methods: {
    close() {
      this.$emit('clickClose')
    },
    submit() {
      this.$emit('clickSubmit')
    }
  }
}
</script>